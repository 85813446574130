<template>
  <div class="MyKPIApplicationDetails">
    <!-- 审核考核详情 -->
    <van-nav-bar
      title="考核详情"
      left-text
      left-arrow
      @click-left="onClickLeft"
      :fixed="true"
    />
    <div class="content">
      <van-form>
        <van-field
          v-model="form.sqnum"
          label="排序号"
          input-align="right"
          readonly
          placeholder="排序号"
        />
        <!-- :rules="[{ required: true, message: '请填写用户名' }]" -->
        <van-field
          v-model="form.quotaType"
          label="指标类别"
          readonly
          input-align="right"
          placeholder="指标类别"
        />
        <van-field
          v-model="form.quotaName"
          label="指标名称"
          readonly
          input-align="right"
          placeholder="指标名称"
        />
        <van-field
          v-model="form.weight"
          label="指标权重"
          type="number"
          readonly
          input-align="right"
          placeholder="指标权重"
        />
        <van-cell class="desc_cell" value="指标描述" />
        <van-field
          class="desc_field"
          v-model="form.quotaDesc"
          rows="8"
          readonly
          autosize
          type="textarea"
          placeholder="指标描述"
        />
        <van-field
          v-model="form.score"
          label="目标值"
          readonly
          type="number"
          input-align="right"
          placeholder="目标值"
        />
      </van-form>
    </div>
    <!-- <div class="footer">
      <van-button type="info"
                  size="small"
                  @click="save">保存</van-button>
    </div> -->
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      form: {
        title: this.$route.query.title,
        sqnum: 1,
        quotaType: "创新部",
        quotaName: "累计销售额目标达成率",
        weight: 0.3,
        quotaDesc:
          "累计销售额是指当年截止考核月份已经完成的销 售金额，累计目标值是指当年截止考核月份需要 完成的销售金额，具体以《2015年月销售额目标 实施方案》为准。",
        score: 1,
      },
    };
  },
  created() {},
  methods: {
    onClickLeft() {
      // this.$router.push('/jxApprovalTagApply')
      this.$router.push({
        path: "/jxMyAssResultsList",
        query: { title: this.$route.query.title },
      });
    },
    onSubmit(values) {
      console.log("submit", values);
    },

    // 添加按钮
    save() {
      // this.$router.push('/jxMy360TargetDetails')
    },
  },
};
</script>

<style lang="less" scoped>
.MyKPIApplicationDetails {
  height: 100vh;
  overflow: hidden;
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .content {
    overflow: auto;
    margin-top: 1.22667rem;
    height: calc(100% - 192px);
    .van-form {
      /deep/.van-field {
        margin: 30px 0;
        .van-cell__title {
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #000000;
        }
        .van-cell__value {
          .van-field__body {
            .van-field__control {
              font-size: 32px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #333333;
            }
          }
        }
      }
      .desc_cell {
        margin-bottom: 0;
        .van-cell__value {
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #000000;
        }
      }
      .desc_field {
        margin-top: 0;
      }
    }
  }
  .footer {
    position: absolute;
    background-color: #ffffff;
    bottom: 0;
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    /* text-align: center; */
    justify-content: center;
    .van-button {
      width: 60%;
      border-radius: 12px;
      .van-button__text {
        font-size: 36px;
        font-family: Source Han Sans SC;
        font-weight: 500;
      }
    }
  }
}
</style>